<template>
  <div class="container">
    <div class="login-wrapper">
      <div class="logo-wrapper">

      </div>

      <!-- login card -->
      <div class="registration-form">
        <v-form @submit.prevent="login" v-model="valid">
          <div class="input-group input-group-sm mb-3">
            <v-text-field
                outlined
                label="Username"
                prepend-icon="mdi-account-circle"
                v-model="email"
                rounded
                autocomplete="off"
                :rules="[rules.required, rules.email]"
            ></v-text-field>
          </div>

          <div class="input-group input-group-sm mb-3">
            <v-text-field
                outlined
                :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                label="Password"
                prepend-icon="mdi-key"
                v-model="password"
                rounded
                autocomplete="off"
                :rules="[rules.required]"
                :type="showpassword ? 'text' : 'password'"
                @click:append="showpassword = !showpassword"
            ></v-text-field>
          </div>

          <div class="form-group text-center justify-center align-content-center">
            <!--        <button type="submit" @click="login" class="btn btn-block create-account">Lets Go</button>-->

          </div>
        </v-form>
        <div class="login_buttons">
          <v-btn
                :disabled="!valid"
                rounded
                color=""
                @click="login"
                x-large
                class="button"
            >
              Lets Go
            </v-btn>
          <br>
          <br>
          <hr />
          <br>
          <v-btn class="nav-link collapsed" @click="resetuser">
            <span>Forgotten Password ?</span></v-btn
          >
        </div>
        <div class="text-center">
          <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </div>
      </div>
      <!-- end of login card -->
    </div>
    <div data-app>
      <v-dialog v-model="badcredentials" max-width="290" persistent>
        <v-card>
          <v-card-title class="text-h5"> User Login Failed </v-card-title>

          <v-card-text> The user details provided are not valid </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="clear"> Continue </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="resetpasword" max-width="290" persistent>
        <v-card>
          <v-card-title class="text-h5"> Hello new user </v-card-title>

          <v-card-text v-if="!reset_true">
            Kindly click the button below to complete your mandatory password
            reset
            <semipolar-spinner
                v-if="spinkit"
                :animation-duration="1500"
                :size="20"
                color="#ff1d5e"
            />
          </v-card-text>
          <v-card-text v-else-if="reset_true" class="success">
            Password reset link sent to {{ email }}. Kindly confirm the link
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                color="green darken-1"
                text
                @click="cofirmation_email"
                v-if="!reset_true"
            >
              Reset Password
            </v-btn>
            <v-btn
                color="green darken-1"
                text
                @click="clear"
                v-else-if="reset_true"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="dialog"
          persistent
          max-width="600px"
      >
        <v-card>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      label="Email*"
                      outlined
                      v-model="email"
                      rounded
                      :rules="[rules.required, rules.email]"
                  ></v-text-field>
                </v-col>
                <v-card-text v-if="reset_true" green>
                  Password reset link sent to {{ email }}. Kindly confirm the
                  link
                </v-card-text>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                @click="cofirmation_email"
                :disabled="!reset_valid"
            >
              Send Reset Link
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-form v-model="reset_valid">
        <v-row justify="center">
          <v-dialog
              v-model="dialog"
              persistent
              max-width="600px"
          >
            <v-card>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                          label="Email*"
                          outlined
                          v-model="email"
                          rounded
                          :rules="[rules.required, rules.email]"
                      ></v-text-field>
                    </v-col>
                    <v-card-text v-if="reset_true" green>
                      Password reset link sent to {{ email }}. Kindly confirm the
                      link
                    </v-card-text>
                  </v-row>
                </v-container>
                <small>*indicates required field</small>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="cofirmation_email"
                    :disabled="!reset_valid"
                >
                  Send Reset Link
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-form>
    </div>

  </div>
</template>

<script>
import { mapMutations } from "vuex";
import axios from "axios";

export default {
  name: "login",

  data: () => {
    return {
      pay_dialog: true,
      email: "",
      valid: false,
      reset_valid: false,
      spinkit: false,
      dialog: false,
      password: "",
      showpassword: false,
      reset_email_sent: false,
      badcredentials: false,
      error_text: "Invalid Credentials",
      overlay: false,
      resetpasword: false,
      reset_true: false,
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },

  mounted(){
    // console.log("===>"+process.env.VUE_APP_BASE_URL)
  },

  methods: {
    ...mapMutations(["setAccessToken", "setRefreshToken"]),

    async resetuser() {
      this.dialog = true;
    },

    async login(e) {
      // dispatch
      this.overlay = true;

      // let myModal = new bootstrap.Modal(document.getElementById('exampleModalCenter'), {});
      // myModal.show();
      const payload = {
        email: this.email,
        password: this.password,
      };

      await new Promise((resolve) => setTimeout(resolve, 3000));
      await axios
          .post(this.$base_url + "/api/auth/login/", payload)
          .then((response) => {
            if (response.status === 200 && response.data.new_user_reset) {
              // console.log("im printing the branch included");
              console.log(response.data);
              this.$store.commit("setRefreshToken", response.data.tokens.refresh);
              this.$store.commit("setAccessToken", response.data.tokens.access);
              this.$store.commit("setLoggedInUser", response.data.email);
              this.$store.commit("setColor", response.data.approved_color);
              this.$store.commit(
                  "setLogo",
                  this.$base_url + "/media/" + response.data.approved_logo
              );
              this.$store.commit("setAuthenticated", true);
              this.$store.commit("setIsManager", response.data.is_manager);
              this.$store.commit("setIsFBCManager", response.data.is_fbcmanager);
              this.$store.commit("setIsAuditor", response.data.is_auditor);
              this.$store.commit("setIsBss", response.data.is_bss);
              this.$store.commit("setIsAgent", response.data.is_agent);
              this.$store.commit("setinstitution", response.data.institution);
              this.$store.commit("setbranch", response.data.branch);

              this.overlay = false;
              // myModal.hide();
              if( response.data.is_bss === true){
                this.$router.push("/user/list");
              }else{
                this.$router.push("/user/dashboard");
              }
            } else {
              this.overlay = false;
              this.$store.commit("setRefreshToken", response.data.tokens.refresh);
              this.$store.commit("setAccessToken", response.data.tokens.access);
              this.$store.commit("setLoggedInUser", response.data.email);
              this.$store.commit("setAuthenticated", true);
              this.$store.commit("setIsManager", response.data.is_manager);
              this.$store.commit("setinstitution", response.data.institution);
              this.resetpasword = true;
            }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.overlay = false;
              this.badcredentials = true;
            } else if (error.response.status === 400) {
              this.overlay = false;
              this.badcredentials = true;
            } else if (error.response.status === 500) {
              this.overlay = false;
              this.badcredentials = true;
              // location.replace("/login");
            }else{
              this.overlay = false;
              this.badcredentials = true;
              // alert("System Error. Please login again.");
              // location.replace("/login");
            }
          });
    },
    async cofirmation_email(e) {
      // dispatch
      // this.overlay = true;
      this.spinkit = true;
      // let myModal = new bootstrap.Modal(document.getElementById('exampleModalCenter'), {});
      // myModal.show();
      const payload = {
        email: this.email,
        redirect_url: this.$base_url+"/reset_password",
      };
      // console.log(payload);
      await new Promise((resolve) => setTimeout(resolve, 3000));
      await axios
          .post(this.$base_url + "/api/auth/request-reset-email/", payload)
          .then((response) => {
            if (response.status === 200) {
              this.spinkit = false;
              // console.log("submited");
              this.reset_true = true;
              this.reset_valid = false;
            }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.overlay = false;
              this.badcredentials = true;
            } else if (error.response.status === 400) {
              this.overlay = false;
              this.badcredentials = true;
            } else if (error.response.status === 500) {
              this.overlay = false;
              this.badcredentials = true;
              // alert("System Error. Please login again.");
              // location.replace("/login");
            }
          });
    },
    async clear() {
      this.badcredentials = false;
      this.email = "";
      this.dialog = false;
      this.password = "";
      this.overlay = false;
      this.resetpasword = false;
      this.reset_true = false;
    },
  },
};
</script>

<style >

.container {
  display: flex;
  justify-content: center;
}


.login-wrapper {
  width: 600px;
  height: 900px;
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  margin-top: 50px;


}

.button {
  display: inline-block;
  border-radius: 7px;
  border: none;
  background: #0065b5;
  color: white;
  font-family: inherit;
  text-align: center;
  font-size: 13px;
  box-shadow: 0px 14px 56px -11px #0065b5;
  width: 10em;
  padding: 1em;
  cursor: pointer;
}

.login_buttons {
  text-align: center;
  align-content: center;
}

.logo-wrapper {
  display: flex;
  overflow: contain;
  width: 250px;
  height: 250px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 50px;
  width: 50%;
  background-image: url("../assets/logo.png");
  background-size: cover;
}
</style>

