<template>
  <div class="container">
    <div class="login-wrapper">
      <div class="logo-wrapper">
      </div>
        <!-- reset password -->
      <div class="registration-form">
        <v-form @submit.prevent="setpassword" v-model="valid">

          <div class="input-group input-group-sm mb-3">

            <v-text-field

                outlined
                label="Password"
                prepend-icon="mdi-key"
                v-model="password1"
                rounded
                :rules="[rules.required]"
                :type="showpass ? 'text' : 'password'"

                >

            </v-text-field>

          </div>

          <div class="input-group input-group-sm mb-3">

            <v-text-field
                outlined

                :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                label="Confirm Password"
                prepend-icon="mdi-key"
                v-model="password2"
                rounded
                counter
                maxlength="15"
                :rules="[rules.required]"
                :type="showpassword ? 'text' : 'password'"
                @click:append="showpassword = !showpassword"
            ></v-text-field>

          </div>


          <div class="form-group login_buttons text-center justify-center">
            <!-- <button type="submit" @click="check_password" class="btn btn-block create-account">Complete Reset</button> -->
            <button class="button"  @click="check_password" style="vertical-align:middle"><span>Reset</span>  </button>
          </div>

        </v-form>
        <div class="text-center">
          <v-overlay :value="overlay">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
          </v-overlay>
        </div>
      </div>
      <!-- end of reset password -->

    </div>


    <div data-app>
      <v-dialog
          v-model="badcredentials"
          max-width="290"
          persistent
      >
        <v-card>
          <v-card-title class="text-h5">
            Password Mismatch
          </v-card-title>

          <v-card-text>
            Please try again
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                color="green darken-1"
                text
                @click="reset_window"
            >
              Continue
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
          v-model="reset_success"
          max-width="290"
          persistent
      >
        <v-card>

          <v-card-text>
            Password reset successful
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                color="green darken-1"
                text
                @click="redirect"
            >
              Proceed to login
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>


  </div>
</template>

<script>
import auth from "@/layouts/auth";
import {mapMutations} from "vuex";
import axios from "axios";

export default {
  name: "Reset",

  created() {
    this.$emit(`update:layout`, auth);

  },
  data: () => {
    return {
      password1: "",
      password2: "",
      reset_success: false,
      showpass:false,
      password_match: "",
      badcredentials: false,
      showpassword:false,
      valid:false,
      overlay: false,
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
    };
  },

  methods: {
    ...mapMutations(["setAccessToken", "setRefreshToken"]),
    // ...mapActions(["logIn"]),


    async check_password(){
        if(this.password1 === this.password2){
          await this.login();
        }
        else{
          this.badcredentials = true;
        }
    },
    async redirect(){
      await this.$router.push("/login");
    },
    async login(e) {
      // dispatch
      this.overlay = true;

      const payload = {
        password: this.password1,
        token: this.$route.query.token,
        uidb64: this.$route.query.uidb64
      };
      await new Promise(resolve => setTimeout(resolve, 3000));
      await axios.patch(this.$base_url+'/api/auth/password-reset-complete', payload)
          .then(response => {
            if (response.status === 200){
              console.log(response.data.success);
              this.overlay = false;
              this.reset_success = true;

            }
            else{
              console.log(response.status);
            }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.badcredentials = true;
            }
            else if(error.response.status === 400) {
              this.badcredentials = true;
            }
            else if(error.response.status === 500) {
              alert('System Error. Please login again.');
              location.replace("/login");
            }
          });
    },
    async clear(){
      this.badcredentials = false;
      this.email = "";
      this.password = "";
      this.overlay= false
    },
    async reset_window(){
      window.location.reload();
    },
  },
};


</script>

<style>
    .container {
  display: flex;
  justify-content: center;
}

.login-wrapper {
  width: 600px;
  height: 900px;
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  margin-top: 50px;
}

.logo-wrapper {
  display: flex;
  overflow: contain;
  width: 250px;
  height: 250px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 50px;
  width: 50%;
  background-image: url("../assets/logo.png");
  background-size: cover;
}

.button {
 display: inline-block;
 border-radius: 7px;
 border: none;
 background: #0065b5;
 color: white;
 font-family: inherit;
 text-align: center;
 font-size: 13px;
 box-shadow: 0px 14px 56px -11px #0065b5;
 width: 10em;
 padding: 1em;
 transition: all 0.4s;
 cursor: pointer;
}

.button span {
 cursor: pointer;
 display: inline-block;
 position: relative;
 transition: 0.4s;
}

    .login_buttons {
      text-align: center;
      align-content: center;
    }

.button span:after {
 content: 'Now';
 position: absolute;
 opacity: 0;
 top: 0;
 right: -20px;
 transition: 0.7s;
}

.button:hover span {
 padding-right: 3.55em;
}

.button:hover span:after {
 opacity: 4;
 right: 0;
}

</style>

