<template>
  <div>
    <v-container
        v-if="true"
        id="user-profile"
        fluid
        tag="section"
    >
      <v-row
          justify="center"
      >
        <v-col
            cols="12"
            md="12"
        >
          <v-row>
            <v-col
                cols="12"
                md="12"
            >
              <v-card-title>
                <div>
                  <v-breadcrumbs :items="links" />
                </div>
                <v-spacer />
                <div
                    class="text-h3 text-center font-weight-medium"
                >
                  Create A Combined Quote
                </div>
                <v-spacer />
                <v-spacer />
              </v-card-title>
            </v-col>
          </v-row>
          <v-container
              class="rounded elevation-0"
          >
            <v-form
                v-model="valid"
                class="rounded elevation-2 pa-3"
                @submit.prevent="CreateQuote"
            >
              <v-row class="mt-2">
                <v-col
                    cols="4"
                    md="3"
                >
                  <v-text-field
                      v-model="vrn"
                      outlined
                      label="VRN"
                      class="purple-input my-input"
                      autocomplete="off"
                      :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                    cols="4"
                    md="3"
                >
                  <v-select
                      v-model="vehicle_type"
                      :items="vrn_type"
                      item-text="name"
                      item-value="value"
                      label="Vehicle Type"
                      outlined
                      :rules="[rules.required]"
                      style="text-transform: uppercase"
                      @change="ChangeVehicle"
                  />
                </v-col>
                <v-col
                    cols="4"
                    md="3"
                >
                  <v-select
                      v-model="vehicle_use"
                      :items="vrn_use"
                      item-text="name"
                      item-value="value"
                      label="Vehicle Use"
                      outlined
                      :rules="[rules.required]"
                      style="text-transform: uppercase"
                  />
                </v-col>
                <v-col
                    cols="4"
                    md="3"
                >
                  <v-select
                      v-model="cover_period"
                      :items="covers"
                      item-text="name"
                      item-value="value"
                      label="Cover Period"
                      outlined
                      :rules="[rules.required]"
                      style="text-transform: uppercase"
                      @change="ChangeFrequency"
                  />
                </v-col>
                <v-col
                    cols="4"
                    md="3"
                >
                  <v-text-field
                      v-model="id_number"
                      outlined
                      label="ID Number"
                      class="purple-input my-input"
                      autocomplete="off"
                      :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                    cols="4"
                    md="3"
                >
                  <v-select
                      v-model="radio"
                      :items="radio_options"
                      item-text="name"
                      item-value="value"
                      label="Add Radio License"
                      outlined
                      :rules="[rules.required]"
                      style="text-transform: uppercase"
                      @change="ChangeRadio"
                  />
                </v-col>
                <v-col
                    cols="12"
                    class="text-center mb-1"
                >
                  <v-btn
                      color="primary"
                      :disabled="!valid"
                      @click="CreateQuote"
                  >
                    Generate
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <br>
          <div
              class="text-h3 text-center font-weight-light elevation-0 rounded-2 pa-2"
          >
            {{ Message }}
          </div>
          <v-container
              class="rounded elevation-0"
              v-if="show_quote"
          >

            <v-row class="mr-2 ml-2 pt-0 pb-0">
              <v-col
                  sm="4"
                  class="pt-0 pb-0"
              >
                <div class="table-responsive card-body pt-3">
                  <table
                      class="table table-bordered"
                      width="100%"
                      cellspacing="0"
                  >
                    <tbody>
                    <tr>
                      <td>
                        <p class="text-left font-weight-light">
                          Stamp Duty
                        </p>
                      </td>
                      <td>
                        $ {{ StampDuty }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p class="text-left font-weight-light">
                          Government Levy
                        </p>
                      </td>
                      <td>
                        $ {{ GovernmentLevy }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p class="text-left font-weight-light">
                          Cover Amount
                        </p>
                      </td>
                      <td>
                        $ {{ CoverAmount }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p class="text-left font-weight-light">
                          Premium Amount
                        </p>
                      </td>
                      <td>
                        $ {{ PremiumAmount }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </v-col>
              <v-divider
                  vertical
                  inset
              />
              <v-col
                  sm="4"
                  class="pt-0 pb-0"
              >
                <div class="table-responsive card-body pt-3">
                  <table
                      class="table table-bordered"
                      width="100%"
                      cellspacing="0"
                  >
                    <tbody>
                    <tr>
                      <td>
                        <p class="text-left font-weight-light">
                          Administration Amount
                        </p>
                      </td>
                      <td>
                        $ {{ AdministrationAmt }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p class="text-left font-weight-light">
                          Arrears Amount
                        </p>
                      </td>
                      <td>
                        $ {{ ArrearsAmt }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p class="text-left font-weight-light">
                          Penalties Amount
                        </p>
                      </td>
                      <td>
                        $ {{ PenaltiesAmt }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p class="text-left font-weight-light">
                          Transaction Amount
                        </p>
                      </td>
                      <td>
                        $ {{ TransactionAmt }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </v-col>
              <v-divider
                  vertical
                  inset
              />
              <v-col
                  sm="4"
                  class="pt-0 pb-0"
              >
                <div class="table-responsive card-body pt-3">
                  <table
                      class="table table-bordered"
                      width="100%"
                      cellspacing="0"
                  >
                    <tbody>
                    <tr>
                      <td>
                        <p class="text-left font-weight-light">
                          Vehicle
                        </p>
                      </td>
                      <td>
                        {{ vehicle_make }} {{ vehicle_model }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p class="text-left font-weight-light">
                          Radio Amount
                        </p>
                      </td>
                      <td>
                        $ {{ RadioTVAmt }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p class="text-left font-weight-light">
                          Radio Arrears Amount Model
                        </p>
                      </td>
                      <td>
                        $ {{ RadioTVArrearsAmt }}
                      </td>
                    </tr>
                    <tr v-if="false">
                      <td>
                        <p class="text-left font-weight-light">
                          Total Radio Amount
                        </p>
                      </td>
                      <td>
                        $ {{ TotalRadioTVAmt }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p class="text-left font-weight-bold">
                          Total Amount
                        </p>
                      </td>
                      <td>
                        <b>$ {{ Amount }}</b>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </v-col>
              <v-col
                  cols="12"
                  class="text-center mb-1"
              >
                <v-btn
                    color="primary"
                    :disabled="ProccedPayment"
                    @click="pay_dialog = true"
                >
                  Proceed to payment
                </v-btn>
              </v-col>
            </v-row>
          </v-container>

          <br><br><br>
          <v-dialog
              v-model="pay_dialog"
              persistent
              width="350"
          >
            <v-card>
              <v-toolbar
                  dark
                  color="primary"
              >
                <v-btn
                    icon
                    dark
                    @click="pay_dialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Process Payment</v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-form
                  v-model="paymentForm_valid"
                  class="rounded elevation-2"
                  @submit.prevent="PaymentUpdate"
              >
                <v-card-text>
                  <v-container>
                    <v-row class="pt-2">
                      <v-col
                          class="pa-0"
                          cols="12"
                          sm="12"
                          md="12"
                      >
                        <v-col
                            class="pa-0 pr-1"
                            cols="12"
                            sm="12"
                            md="12"
                        >
                          <v-text-field
                              v-model="client_phonenumber"
                              label="Client Mobile Number"
                              placeholder="0772000000"
                              outlined
                              :rules="[rules.counter , rules.required]"
                          />
                        </v-col>
                        <v-col
                            class="pa-0 pr-1"
                            cols="12"
                            sm="12"
                            md="12"
                        >
                          <v-select
                              v-model="delivery_method"
                              outlined
                              :items="delivery_methods"
                              item-text="name"
                              item-value="value"
                              label="Select Delivery Method"
                              :rules="[rules.required]"
                          />
                        </v-col>
                        <v-col
                            class="pa-0 pr-1"
                            cols="12"
                            sm="12"
                        >
                          <v-select
                              v-model="payment_method"
                              outlined
                              :items="payment_methods"
                              item-text="name"
                              item-value="value"
                              label="Select Payment Method"
                              :rules="[rules.required]"
                          />
                        </v-col>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions
                    class="pt-0 text-center justify-center mb-1"
                >
                  <v-btn
                      color="primary"
                      :disabled="!paymentForm_valid"
                      @click="PaymentUpdate"
                  >
                    Send Request
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
          <v-dialog
              v-model="retrieve_policyDialog"
              persistent
              width="300"
          >
            <v-card>
              <v-toolbar
                  dark
                  color="primary"
                  class="justify-center text-center align-content-center"
              >
                <v-btn
                    v-if="false"
                    icon
                    dark
                    @click="retrieve_policyDialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title class="justify-center text-center align-content-center" >Payment Request</v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-card-text class="text-center">
                Sending Payment Request {{ payment_message }}
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                    v-if="payment_message === ''"
                    loading
                    color="primary"
                >
                  Retrieve Quote Information
                </v-btn>
                <v-btn
                    v-else
                    color="primary"
                    @click="ViewTransaction"
                >
                  Retrieve Quote Information
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
              v-model="trans_summary"
              persistent
              width="350"
          >
            <v-card>
              <v-toolbar
                  dark
                  color="primary"
              >
                <v-toolbar-title class="text-center">
                  Transaction Status
                </v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <div class="text-center justify-center">
                <br>
                <h2
                    v-if="Status === 'Rejected'"
                    style="color: #73030d"
                >
                  {{ Status }}
                </h2>
                <h2
                    v-else-if="Status === 'Loaded'"
                    style="color: #F39B00"
                >
                  {{ Status }}
                </h2>
                <h2
                    v-else
                    style="color: #4caf50"
                >
                  {{ Status }}
                </h2>
                <v-row class="mr-2 ml-2 pt-0 pb-0">
                  <v-col
                      sm="12"
                      md="12"
                      lg="12"
                  >
                    <v-card-actions
                        class="justify-center"
                    >
                      <div v-if="Status === 'Loaded'">
                        <v-img
                            src="@/assets/warning.svg"
                            width="10em"
                        />
                      </div>
                      <div v-else-if="Status === 'Rejected'">
                        <v-img
                            src="@/assets/error.svg"
                            width="10em"
                        />
                      </div>
                      <div v-else>
                        <v-img
                            src="@/assets/success.svg"
                            width="10em"
                        />
                      </div>
                    </v-card-actions>
                  </v-col>
                  <v-col
                      sm="12"
                      md="12"
                      lg="12"
                  >
                    <v-card-actions
                        class="justify-center"
                    >
                      <v-btn
                          v-if="Status === 'Loaded'"
                          :loading="EcocashWait > 1"
                          color="primary"
                          @click="ViewTransaction"
                      >
                        Refresh Transaction
                      </v-btn>
                      <v-btn
                          v-else-if="Status === 'Rejected'"
                          color="primary"
                          @click="Save"
                      >
                        Finish Transaction
                      </v-btn>
                      <v-btn
                          v-else
                          color="primary"
                          @click="Save"
                      >
                        Finish Transaction
                      </v-btn>
                    </v-card-actions>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <br><br><br>
    </v-container>
    <div class="text-center">
      <v-overlay :value="overlay">
        <v-progress-circular
            indeterminate
            size="64"
        />
      </v-overlay>
    </div>
    <br><br><br><br>
  </div>
</template>
<script>
// import { mapState } from 'vuex'
import axios from 'axios'
import { API } from '../../../api'
import moment from 'moment'
export default {
  name: 'Combined',
  data () {
    return {
      vrn: '',
      Status: '',
      id_number: '',
      cover_period: '',
      ProccedPayment: true,
      show_quote: false,
      Message: "",
      EcocashWait: 0,
      trans_summary: false,
      retrieve_policyDialog: false,
      payment_message: '',
      pay_dialog: false,
      paymentForm_valid: false,
      valid: false,
      overlay: false,
      vehicle_type: '',
      vrn_type: [{ name: 'Ambulance and Fire Engines, Hearse', value: 35 },
        { name: 'Commercial Trailers', value: 14 },
        { name: 'Commercial Vehicle', value: 8 },
        { name: 'Motor Cycles', value: 19 },
        { name: 'Omnibus and Commuters', value: 22 },
        { name: 'Private Vehicle', value: 1 },
        { name: 'School Bus', value: 25 },
        { name: 'Special Types', value: 37 },
        { name: 'Staff Bus', value: 29 },
        { name: 'Taxi', value: 13 },
        { name: 'Tractors, Fork Lifts and Combines', value: 31 },
        { name: 'Trailer', value: 6 },
      ],
      vehicle_use: '',
      vehicle_make: 'null',
      vehicle_model: 'null',
      yearOfmanufacture: 'null',
      tax_class: '',
      vrn_use: [],
      qoutes: [],
      radio: 0,
      radio_options: [{ name: 'Yes', value: 1 },
        { name: 'No', value: 9 }],
      delivery_methods: [{ name: 'Postal', value: 1 },
        { name: 'Office Collection', value: 2 }],
      delivery_method: '',
      covers: [{ name: '1 month', value: 1 },
        { name: '2 months', value: 2 },
        { name: '3 months', value: 3 },
        { name: 'Term', value: 4 },
        { name: '5 months', value: 5 },
        { name: '6 months', value: 6 },
        { name: '7 months', value: 7 },
        { name: 'Two Terms', value: 8 },
        { name: '9 months', value: 9 },
        { name: '10 months', value: 10 },
        { name: '11 months', value: 11 },
        { name: 'Annual', value: 12 }],
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length >= 10 || 'Max 10 characters',
        counter_id: value => value.length >= 11 || 'Max 11 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
      links: [
        {
          text: 'Combined',
          disabled: false,
          href: 'generate',
        },
        {
          text: 'Generate Quote',
          disabled: true,
          href: '',
        },
      ],
      payment_methods: [],
      payment_method: '',
      client_idnumber: '',
      client_phonenumber: '',
    }
  },
  created () {
    if(this.$store.getters.is_auditor){
      this.payment_methods = [
        { name: 'Pay Now - Ecocash Online Payment', value: 3 }
      ]
    }else{
      this.payment_methods = [
        { name: 'Pay Now - Ecocash Online Payment', value: 3 },
        { name: 'Pay Later - Cash', value: 1 }
      ]
    }
  },
  watch: {
    EcocashWait: {
      handler (value) {
        if (value > 0) {
          setTimeout(() => {
            this.EcocashWait--
          }, 1000)
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    async ChangeRadio () {
      if (this.radio === 1) {
        this.RadioType = 'Radio'
      } else {
        this.RadioType = 'null'
      }
    },
    async ChangeFrequency () {
      if (this.cover_period === 4) {
        this.frequency = 1
      } else if (this.cover_period === 6) {
        this.frequency = 2
      } else if (this.cover_period === 12) {
        this.frequency = 3
      } else if (this.cover_period === 5) {
        this.frequency = 4
      } else if (this.cover_period === 7) {
        this.frequency = 5
      } else if (this.cover_period === 8) {
        this.frequency = 6
      } else if (this.cover_period === 9) {
        this.frequency = 7
      } else if (this.cover_period === 10) {
        this.frequency = 8
      } else if (this.cover_period === 11) {
        this.frequency = 9
      } else if (this.cover_period === 1) {
        this.frequency = 10
      } else if (this.cover_period === 2) {
        this.frequency = 11
      } else if (this.cover_period === 3) {
        this.frequency = 12
      }
    },
    async ChangeVehicle () {
      if (this.vehicle_type === 1) {
        this.vrn_use = [{ name: 'Private Own Use', value: 1 }, { name: 'Private Hire and Reward', value: 13 },
          { name: 'Private Fleet', value: 9 }, { name: 'Private Business Use', value: 5 },
          { name: 'Private Driving School', value: 17 }]
      } else if (this.vehicle_type === 8) {
        this.vrn_use = [{ name: 'Commercial Own Use', value: 25 }, { name: 'Commercial Hire and Reward', value: 29 },
          { name: 'Commercial Fleet Own Use', value: 33 }, { name: 'Commercial Fleet Hire and Reward', value: 37 },
          { name: 'Commercial Driving School', value: 60 }]
      } else if (this.vehicle_type === 22) {
        this.vrn_use = [{ name: 'Up to 30 Seats', value: 81 }, { name: 'Between 31 - 60', value: 84 },
          { name: 'More than 60 Seats', value: 87 }]
      } else if (this.vehicle_type === 35) {
        this.vrn_use = [{ name: 'Various', value: 59 }]
      } else if (this.vehicle_type === 37) {
        this.vrn_use = [{ name: 'Contractors Plant and Equipment ( Dozers, Graders and the like)', value: 59 }]
      } else if (this.vehicle_type === 13) {
        this.vrn_use = [{ name: 'Public Hire', value: 64 }]
      } else if (this.vehicle_type === 19) {
        this.vrn_use = [{ name: 'Personal Use', value: 78 }, { name: 'Business Use', value: 79 },
          { name: ' Fleet Use', value: 80 }]
      } else if (this.vehicle_type === 6) {
        this.vrn_use = [{ name: 'Domestic Trailers', value: 21 }, { name: 'Caravans', value: 23 }]
      } else if (this.vehicle_type === 29) {
        this.vrn_use = [{ name: 'Up to 30 Seats', value: 91 }, { name: 'Between 31 - 60', value: 41 },{ name: 'More than 60 Seats', value: 45 }]
      } else if (this.vehicle_type === 31) {
        this.vrn_use = [{ name: 'Own Use', value: 100 },{ name: ' Hire and Reward', value: 47 },{ name: 'Agriculture - Own use', value: 48 },{ name: 'Agriculture - Hire and Reward', value: 49 }]
      } else if (this.vehicle_type === 25) {
        this.vrn_use = [{ name: 'Up to 30 Seats', value: 91 }, { name: 'Between 31 - 60', value: 94 },
          { name: 'More than 60 Seats', value: 97 }]
      } else if (this.vehicle_type === 14) {
        this.vrn_use = [{ name: 'Own Use', value: 68 }, { name: 'Hire and Reward', value: 70 },
          { name: 'Fleet - Own Use', value: 72 }, { name: 'Fleet - Hire and Reward', value: 75 },
          { name: 'Agriculture', value: 77 }]
      }
    },
    async CreateQuote () {
      this.overlay = true
      this.StampDuty = '---'
      this.GovernmentLevy = '---'
      this.CoverAmount = '---'
      this.PremiumAmount = '---'
      this.vehicle_make = 'null'
      this.vehicle_model = 'null'
      this.vehicle_value = '---'
      this.Amount = '---'
      this.PremiumAmount = '---'
      await new Promise(resolve => setTimeout(resolve, 2000))
      // try{
      const JWTToken = this.$store.getters.accessToken
      axios.get(API + '/api/insurance/combined/?request_type=1&id_number=' + this.id_number + '&frequency=' + this.frequency + '&duration_months=' + this.cover_period + '&radio_usage=' + this.radio + '&vrn=' +
          this.vrn + '&vrn_type=' + this.vehicle_type + '&tax_class=' + this.vehicle_use, { headers: { Authorization: `Bearer ${JWTToken}` } })
          .then(res => {
            if (res.status === 200) {
              console.log(res.data)
              this.responsedata = true
              this.show_quote = true
              this.overlay = false
              // response data
              let result = res.data.Response.Result
              if(result === 1){
                this.ProccedPayment = false
              }else{
                this.ProccedPayment = true
              }
              this.CombinedID = res.data.Response.Quotes[0].CombinedID
              this.Amount = res.data.Response.Quotes[0].Amount
              this.AdministrationAmt = res.data.Response.Quotes[0].Licence.AdministrationAmt
              this.Message = res.data.Response.Quotes[0].Message
              this.ArrearsAmt = res.data.Response.Quotes[0].Licence.ArrearsAmt
              this.PenaltiesAmt = res.data.Response.Quotes[0].Licence.PenaltiesAmt
              this.RadioTVAmt = res.data.Response.Quotes[0].Licence.RadioTVAmt
              this.RadioTVArrearsAmt = res.data.Response.Quotes[0].Licence.RadioTVArrearsAmt
              this.LicFrequency = res.data.Response.Quotes[0].Licence.LicFrequency
              this.TotalAmount = res.data.Response.Quotes[0].Licence.TotalAmount
              this.TotalLicAmt = res.data.Response.Quotes[0].Licence.TotalLicAmt
              this.TransactionAmt = res.data.Response.Quotes[0].Licence.TransactionAmt
              this.TotalRadioTVAmt = res.data.Response.Quotes[0].Licence.TotalRadioTVAmt
              this.StampDuty = res.data.Response.Quotes[0].Policy.StampDuty
              this.GovernmentLevy = res.data.Response.Quotes[0].Policy.GovernmentLevy
              this.CoverAmount = res.data.Response.Quotes[0].Policy.CoverAmount
              this.PremiumAmount = res.data.Response.Quotes[0].Policy.PremiumAmount
              this.DurationMonths = res.data.Response.Quotes[0].Policy.DurationMonths
              this.vehicle_make = res.data.Response.Quotes[0].Vehicle.Make
              this.vehicle_model = res.data.Response.Quotes[0].Vehicle.Model
              console.log(this.Message)
            } else if (res.status === 202) {
              this.responsedata = true
              this.overlay = false
              this.quote_message = res.data.message.Response.Quotes[0].Message
            } else {
              this.overlay = false
              window.clearTimeout(this.timeoutID)
              this.$store.commit('clearUserData')
              alert('Your Session has ended')
              // this.$router.push("/login");
              window.location.href = '/login'
            }
          })
    },
    async PaymentUpdate () {
      this.overlay = true
      this.pay_dialog = false
      this.payment_message = ''
      this.EcocashWait = 7
      this.retrieve_policyDialog = true
      await new Promise(resolve => setTimeout(resolve, 2000))
      const JWTToken = this.$store.getters.accessToken
      axios.get(API + '/api/insurance/combined/?payment_method=' + this.payment_method + '&delivery_method=' +
          this.delivery_method + '&combined_id=' + this.CombinedID + '&payment_status=1&client_mobile=' + this.client_phonenumber + '&request_type=2', { headers: { Authorization: `Bearer ${JWTToken}` } })
          .then(res => {
            if (res.status === 200) {
              console.log(res.data)
              // this.payment_message = res.data.Response.Message
              this.payment_message = 'Successful'
              this.overlay = false
            } else {
              this.overlay = false
              window.clearTimeout(this.timeoutID)
              this.$store.commit('clearUserData')
              alert('Your Session has ended')
              // this.$router.push("/login");
              window.location.href = '/login'
            }
          })
    },
    async ViewTransaction () {
      this.EcocashWait = 7
      this.retrieve_policyDialog = false
      this.overlay = true
      await new Promise(resolve => setInterval(resolve, 100))
      // try{
      const JWTToken = this.$store.getters.accessToken
      axios.get(API + '/api/insurance/combined/?combined_id=' + this.CombinedID + '&request_type=3', { headers: { Authorization: `Bearer ${JWTToken}` } })
          .then(res => {
            if (res.status === 200) {
              console.log(res.data)
              this.overlay = false
              // response data
              this.LicenceID = res.data.Response.LicenceID
              this.PolicyNo = res.data.Response.PolicyNo
              this.PremiumAmount = res.data.Response.PremiumAmount
              this.CoverAmount = res.data.Response.CoverAmount
              this.ApprovedBy = res.data.Response.ApprovedBy
              this.Currency = res.data.Response.Currency
              this.DurationMonths = res.data.Response.DurationMonths
              this.EndDate = res.data.Response.EndDate
              this.GovernmentLevy = res.data.Response.GovernmentLevy
              this.LoadedBy = res.data.Response.LoadedBy
              this.LoadedDate = res.data.Response.LoadedDate
              this.Make = res.data.Response.Make
              this.Model = res.data.Response.Model
              this.Rate = res.data.Response.Rate
              this.StampDuty = res.data.Response.StampDuty
              this.StartDate = res.data.Response.StartDate
              this.Status = res.data.Response.Status
              this.ValueAmount = res.data.Response.ValueAmount
              this.retrieve_policyDialog = false
              this.PartnerReference = res.data.PartnerReference
              this.trans_summary = true
            } else if (res.status === 202) {
              this.responsedata = true
              this.overlay = false
              this.quote_message = res.data.message.Response.Quotes[0].Message
            } else {
              this.overlay = false
              window.clearTimeout(this.timeoutID)
              this.$store.commit('clearUserData')
              alert('Your Session has ended')
              // this.$router.push("/login");
              window.location.href = '/login'
            }
          })
    },
    async Save () {
      let PayMethod
      if (this.payment_method === 1){
        PayMethod = "Pay Later - Cash or POS"
      }
      else if (this.payment_method === 3){
        PayMethod = "Paid - Mobile Payments"
      }
      if ( this.Status === 'Rejected'){
        this.PolicyNo = '0000000'
      }
      this.EcocashWait = 7
      this.overlay = true
      await new Promise(resolve => setTimeout(resolve, 2000))
      const JWTToken = this.$store.getters.accessToken
      this.vrn = this.vrn.toUpperCase()
      this.receipt = "REC" + this.CombinedID
      const TransInfo = {
        product_name:"Combined Insurance",
        vrn: this.vrn,
        transaction_id: this.CombinedID,
        policy_number: this.PolicyNo,
        receipt_number : this.receipt,
        transaction_amount : this.Amount,
        commissionable : this.PremiumAmount,
        transaction_status : this.Status,
        payment_type : PayMethod,
        customer_name : this.Owner_FirstName,
        customer_email : this.Owner_LastName,
        customer_cell : this.client_phonenumber,
        customer_IDnumber : this.client_idnumber
      }
      console.log('TransInfo', TransInfo)
      axios.request({
        url: '/api/insurance/agent_transaction/',
        method: 'post',
        baseURL: API,
        headers: {
          Authorization: `Bearer ${JWTToken}`,
          'Content-Type': 'application/json',
        },
        data: TransInfo,
      }).then(res => {
        if (res.status === 201) {
          console.log(res.data)
          this.overlay = false
          this.retrieve_policyDialog = false
          this.trans_summary = true
          this.clear()
        } else if (res.status === 202) {
          this.responsedata = true
          this.overlay = false
        } else {
          this.overlay = false
          window.clearTimeout(this.timeoutID)
          this.$store.commit('clearUserData')
          alert('Your Session has ended')
          // this.$router.push("/login");
          window.location.href = '/login'
        }
      })
      this.clear()
    },
    clear () {
      this.trans_summary = false
      this.$router.push('/user/dashboard')
    },
  },
}
</script>
<style>
.my-input input{
  text-transform: uppercase
}
</style>
