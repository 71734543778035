import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import Login from './views/Login'
import Reset from './views/reset_password'
import Dashboard from '../src/views/dashboard/Dashboard'
import DashboardIndex from '../src/views/dashboard/Index'

import ThirdParty from "@/views/dashboard/pages/ThirdParty";
import Licensing from "@/views/dashboard/pages/Licensing";
import Combined from "@/views/dashboard/pages/Combined";
import User from "@/views/dashboard/pages/UserProfile";
import Branch from "@/views/dashboard/pages/Branch";
import Agency from "@/views/dashboard/pages/Agency";
// import { checkTokenStatus } from './services/auth.service'

Vue.use(Router)
const routes = [
  {
    path: '/',
    component: Login,
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/reset_password',
    component: Reset,
  },
  {
    path: '/user',
    component: DashboardIndex,
    children: [
      // {
      //   path: 'dashboard',
      //   component: Dashboard,
      //   beforeEnter: async (to, from, next) => {
      //     if (await checkTokenStatus() === 401) {
      //       next('/login')
      //     } else {
      //       next()
      //     }
      //   },
      // },
      {
        path: 'dashboard',
        component: Dashboard,
        beforeEnter: (to, from, next) => {
          if(store.state.isAuthenticated === false || store.state.is_bss === true) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: 'third',
        component: ThirdParty,
        beforeEnter: (to, from, next) => {
          if(store.state.isAuthenticated === false) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: 'licensing',
        component: Licensing,
        beforeEnter: (to, from, next) => {
          if(store.state.isAuthenticated === false) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: 'combined',
        component: Combined,
        beforeEnter: (to, from, next) => {
          if(store.state.isAuthenticated === false) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: 'list',
        component: User,
        beforeEnter: (to, from, next) => {
          if(store.state.isAuthenticated === false) {
            next("/login");
          } else {
            next();
          }
        }
      },
    ],

  },
  {
    path: '/branch',
    component: DashboardIndex,
    children:[
     {
      path: 'list',
      component: Branch,
     },
      {
        path: 'agency',
        component: Agency,
      }
    ]
  },
]
const router = new Router(
  {
    routes,
    mode: 'history',
  },
)

export default router

