<template>
  <v-container
      id="dashboard"
      fluid
      tag="section"
  >
    <div>
      <v-row>
        <v-col
            cols="12"
            sm="6"
            lg="3"
        >
          <base-material-stats-card
            color="primary"
            icon="mdi-alpha-c-circle"
            title="Commission"
            :value="commission"
            sub-icon="mdi-currency-usd"
            sub-text="Current Month Commission"
          />
        </v-col>
        <v-col
            cols="12"
            sm="6"
            lg="3"
        >
          <base-material-stats-card
            color="primary"
            icon="mdi-alpha-t-circle"
            title="3rd Party"
            :value="third_party_count"
            sub-icon="mdi-wallet-plus"
            sub-text="Approved Third Party RTA"
          />
        </v-col>
        <v-col
            cols="12"
            sm="6"
            lg="3"
        >
          <base-material-stats-card
            color="primary"
            icon="mdi-alpha-c-circle"
            title="Combined"
            :value="String(thirdLand_zinara_count)"
            sub-icon="mdi-wallet-plus"
            sub-text="Approved Combined Transactions"
          />
        </v-col>
        <v-col
            cols="12"
            sm="6"
            lg="3"
        >
          <base-material-stats-card
            color="primary"
            icon="mdi-alpha-l-circle"
            title="Licensing"
            :value="String(zinara_count)"
            sub-icon="mdi-wallet-plus"
            sub-text="Approved Licensing Transactions"
          />
        </v-col>
        <v-col v-if="fbcmanager_state === false"  cols="12" md="12" style="background-color: whitesmoke">

          <v-card-title>
            Agent Transactions

            <template v-if="manager_state == true">
              <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  @click="createExcel()"
              >
                <v-icon dark>
                  mdi-download
                </v-icon>
              </v-btn>
            </template>
            <v-spacer></v-spacer>
            <br>
            <br>
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="date"
                    label="Select Commission Period"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>

              </template>

              <v-date-picker
                  v-model="date"
                  type="month"
                  no-title
                  scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                    text
                    color="secondary"
                    @click="OnDateChange"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>

          </v-card-title>
          <v-data-table
              :headers="headers"
              :items="transactions"
              :search="search"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
          >
            <template v-slot:item.transaction_status="{ item }">
              <v-chip
                  v-if="item.transaction_status === 'Success'"
                  color="success"
                  dark
              >
                COMPLETED
              </v-chip>
              <v-chip
                  v-if="item.transaction_status === 'Rejected'"
                  color="danger"
                  dark
              >
                REJECTED
              </v-chip>
            </template>
            <template v-slot:item.transaction_date="{ item }">
              <span>{{ new Date(item.transaction_date).toLocaleString() }}</span>
            </template>
          </v-data-table>
        </v-col>
        <v-col v-if="auditor_state === true || fbcmanager_state === true"  cols="12" md="12" style="background-color: whitesmoke">

          <v-card-title>
            All Transactions

            <template>
              <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  @click="createExcel()"
              >
                <v-icon dark>
                  mdi-download
                </v-icon>
              </v-btn>
            </template>
            <v-spacer></v-spacer>
            <br>
            <br>
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="date"
                    label="Select Commission Period"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>

              </template>

              <v-date-picker
                  v-model="date"
                  type="month"
                  no-title
                  scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                    text
                    color="secondary"
                    @click="AuditorDateChange"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>

          </v-card-title>
          <v-data-table
              :headers="headers"
              :items="all_transactions"
              :search="search"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
          >
            <template v-slot:item.transaction_status="{ item }">
              <v-chip
                  v-if="item.transaction_status === 'Success'"
                  color="success"
                  dark
              >
                COMPLETED
              </v-chip>
              <v-chip
                  v-if="item.transaction_status === 'Rejected'"
                  color="danger"
                  dark
              >
                REJECTED
              </v-chip>
            </template>
            <template v-slot:item.transaction_date="{ item }">
              <span>{{ new Date(item.transaction_date).toLocaleString() }}</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
  import { mapState } from 'vuex'
  import axios from 'axios'
  import moment from 'moment'
  import xlsx from 'xlsx'
  export default {
    name: 'Dashboard',
    data () {
      return {
        sortBy: 'id',
        sortDesc: true,
        auditor_state : this.$store.getters.is_auditor,
        fbcmanager_state : this.$store.getters.is_fbcmanager,
        manager_state : this.$store.getters.is_manager,
        user_branch : this.$store.getters.branch,
        date: new Date().toISOString().substr(0, 7),
        menu: false,
        dateformated:'',
        modal: false,
        billingMonth:  ('0' + (new Date().getMonth()+1)).slice(-2)+'-'+new Date().getFullYear(),
        year: new Date().getFullYear(),
        isloading: false,
        requests: '',
        amountowing: '',
        transactions:[],
        all_transactions:[],
        results: false,
        search: '',
        commission:'0',
        third_party_count:'0',
        thirdLand_zinara_count:'0',
        zinara_count:'0',
        overlay: false,
      };
    },
    created() {
      if(this.is_auditor){
        this.All_Trans();
      }
      this.Sales_Trans();
      this.TotalCommission();
      if (this.$store.getters.is_manager){
        this.headers = [
          {
            text: 'ID',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Product', value: 'product_name' },
          { text: 'VRN', value: 'vrn' },
          { text: 'TranID', value: 'transaction_id' },
          { text: 'Policy Number', value: 'policy_number' },
          { text: 'Receipt Number', value: 'receipt_number' },
          { text: 'Amount', value: 'transaction_amount' },
          { text: 'Commission', value: 'transaction_commission' },
          { text: 'Date', value: 'transaction_date' },
          { text: 'Branch', value: 'branch' },
          {text:  'Status', value:'transaction_status'},
        ]}
      else{
        this.headers = [
          {
            text: 'ID',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Product Name', value: 'product_name' },
          { text: 'VRN', value: 'vrn' },
          { text: 'Transaction ID', value: 'transaction_id' },
          { text: 'Policy Number', value: 'policy_number' },
          { text: 'Receipt Number', value: 'receipt_number' },
          { text: 'Transaction Amount', value: 'transaction_amount' },
          { text: 'Date', value: 'transaction_date' },
          {text:  'Status', value:'transaction_status'},
        ]
      }
      if(this.manager_state == true){
        let UserTransactions = this.transactions.filter((item) => {
          return (item.branch === this.user_branch)
        })
        this.transactions = UserTransactions
        console.log(this.transactions)
      }
    },
    computed: {
      ...mapState(['is_auditor', 'access_token', 'base_url', 'is_fbcmanager']),

    },
    methods: {
      async OnDateChange(){
        console.log(this.user_branch + 'ndini branch')
        this.overlay = true;
        let JWTToken = this.$store.getters.accessToken;
        this.dateformated = this.date.replace(/T.*/,'').split('-').reverse().join('-')
        console.log(this.dateformated, 'im the date');
        axios.get(this.$base_url+'/api/commissions/transactions/?period='+this.dateformated, { headers: {"Authorization" : `Bearer ${JWTToken}`} })
            .then(res => {this.$refs.menu.save(this.date);
              if(res.status === 200){
                this.overlay = false,
                    console.log('im here', res.data);
                console.log('is manager or not',this.$store.getters.is_manager)
                this.third_party_count= res.data.rta_count;
                this.thirdLand_zinara_count= res.data.insurance_zinara_count;
                this.zinara_count= res.data.zinara_count;
                this.transactions = res.data;

                if(this.manager_state == false ){
                  let UserTransactions = this.transactions.filter((item) => {
                    return (item.branch === this.user_branch)
                  })
                  this.transactions = UserTransactions
                  console.log(UserTransactions + 'its me ')
                  console.log(this.transactions)
                }
              }

            })
            .catch(error => console.log(error))

        axios.get(this.$base_url+'/api/commissions/commission/?period='+this.dateformated, { headers: {"Authorization" : `Bearer ${JWTToken}`} })
            .then(res => {
              if(res.status === 200){
                console.log('im here', res.data);
                console.log('is manager or not',this.$store.getters.is_manager)
                this.commission = res.data.commission_earned.transaction_commission__sum;
                this.third_party_count= res.data.rta_count;
                this.thirdLand_zinara_count= res.data.insurance_zinara_count;
                this.zinara_count= res.data.zinara_count;
                this.isloading=false;
              }

            })
            .catch(error => console.log(error))
      },

      async AuditorDateChange(){
        console.log(this.user_branch + 'ndini branch')
        this.overlay = true;
        let JWTToken = this.$store.getters.accessToken;
        this.dateformated = this.date.replace(/T.*/,'').split('-').reverse().join('-')
        console.log(this.dateformated, 'im the date');
        axios.get(this.$base_url+'/api/commissions/all_transactions/?period='+this.dateformated, { headers: {"Authorization" : `Bearer ${JWTToken}`} })
            .then(res => {this.$refs.menu.save(this.date);
              if(res.status === 200){
                this.overlay = false,
                    console.log('im here', res.data);
                console.log('is manager or not',this.$store.getters.is_manager)
                this.third_party_count= res.data.rta_count;
                this.thirdLand_zinara_count= res.data.insurance_zinara_count;
                this.zinara_count= res.data.zinara_count;
                this.all_transactions = res.data;
              }

            })
            .catch(error => console.log(error))

        axios.get(this.$base_url+'/api/commissions/commission/?period='+this.dateformated, { headers: {"Authorization" : `Bearer ${JWTToken}`} })
            .then(res => {
              if(res.status === 200){
                console.log('im here', res.data);
                console.log('is manager or not',this.$store.getters.is_manager)
                this.commission = res.data.commission_earned.transaction_commission__sum;
                this.third_party_count= res.data.rta_count;
                this.thirdLand_zinara_count= res.data.insurance_zinara_count;
                this.zinara_count= res.data.zinara_count;
                this.isloading=false;
              }

            })
            .catch(error => console.log(error))
      },

      async downloadReport(){

        console.log("downloadReport button clicked")

        let JWTToken = this.$store.getters.accessToken;
        var config = {
          method:"get",
          url: this.$base_url+"/api/commissions/generate-pdf",

          headers: {Authorization:`Bearer ${JWTToken}`}
        };

        axios(config)
        .then((response)=>{
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fURL = document.createElement('a');

          fURL.href = fileURL;
          fURL.setAttribute('download', 'file.pdf');
          document.body.appendChild(fURL);

          fURL.click();
        });
      },

      async createExcel () {
        const filename = String('transactions_report - ' + moment(Date()).format('MM/DD/YYYY') + '.xlsx')
        const data = this.transactions
        console.log("createExcel method called")
        console.log(this.transactions)
        var ws = xlsx.utils.json_to_sheet(data)
        var wb = xlsx.utils.book_new()
        xlsx.utils.book_append_sheet(wb, ws, 'transaction')
        xlsx.writeFile(wb, filename)
      },

      async Sales_Trans(){
        this.isloading = true;
        // this.dateformated = this.date.replace(/T.*/,'').split('-').reverse().join('-')
        // console.log(this.dateformated, 'im the date');
        await  new Promise(resolve => setTimeout(resolve, 2000));
        this.dateformated = this.date.replace(/T.*/,'').split('-').reverse().join('-')
        // try{
        let JWTToken = this.$store.getters.accessToken;

        axios.get(this.$base_url+'/api/commissions/transactions/?period='+this.dateformated, { headers: {"Authorization" : `Bearer ${JWTToken}`} })
            .then(res => {
              if(res.status === 200){
                console.log('im here', res.data);
                console.log('is manager or not',this.$store.getters.is_manager)
                this.third_party_count= res.data.rta_count;
                this.thirdLand_zinara_count= res.data.insurance_zinara_count;
                this.zinara_count= res.data.zinara_count;
                this.transactions = res.data;
                if(this.manager_state == false){
                  let UserTransactions = this.transactions.filter((item) => {
                    return (item.branch === this.user_branch)
                  })
                  this.transactions = UserTransactions
                  console.log(UserTransactions + 'its me ')
                  console.log(this.transactions)
                }
              }

            })
            .catch(error => console.log(error))
        // }catch(e){
        //   console.error(e)
        // }
      },

      async All_Trans(){
        this.isloading = true;
        // this.dateformated = this.date.replace(/T.*/,'').split('-').reverse().join('-')
        // console.log(this.dateformated, 'im the date');
        await  new Promise(resolve => setTimeout(resolve, 2000));
        this.dateformated = this.date.replace(/T.*/,'').split('-').reverse().join('-')
        // try{
        let JWTToken = this.$store.getters.accessToken;

        axios.get(this.$base_url+'/api/commissions/all_transactions/?period='+this.dateformated, { headers: {"Authorization" : `Bearer ${JWTToken}`} })
            .then(res => {
              if(res.status === 200){
                this.third_party_count= res.data.rta_count;
                this.thirdLand_zinara_count= res.data.insurance_zinara_count;
                this.zinara_count= res.data.zinara_count;
                this.all_transactions = res.data;
              }
            })
            .catch(error => console.log(error))
        // }catch(e){
        //   console.error(e)
        // }
      },

      async TotalCommission(){
        this.isloading = true;
        await  new Promise(resolve => setTimeout(resolve, 2000));
        this.dateformated = this.date.replace(/T.*/,'').split('-').reverse().join('-')
        // try{
        let JWTToken = this.$store.getters.accessToken;

        axios.get(this.$base_url+'/api/commissions/commission/?period='+this.dateformated, { headers: {"Authorization" : `Bearer ${JWTToken}`} })
            .then(res => {
              if(res.status === 200){
                console.log('im here', res.data);
                console.log('is manager or not',this.$store.getters.is_manager)
                this.commission = res.data.commission_earned.transaction_commission__sum;
                this.third_party_count= res.data.rta_count;
                this.thirdLand_zinara_count= res.data.insurance_zinara_count;
                this.zinara_count= res.data.zinara_count;
                this.isloading=false;
              }

            })
            .catch(error => console.log(error))
        // }catch(e){
        //   console.error(e)
        // }
      },

      async getStats(){
        this.isloading = true;
        await new Promise(resolve => setTimeout(resolve, 2000));
        let month1 = new Date().getMonth()+1;
        let mwedzi = month1.toString();
        if(mwedzi.length === 1){
          let month2 = '0'+mwedzi;
          console.log(month2 + 'ndini mwedzi');
          try{
            let JWTToken = this.$store.getters.accessToken;
            axios.get('https://xarani.net/billing/transactions/?period='+month2+'-'+this.year, { headers: {"Authorization" : `Bearer ${JWTToken}`} })
                .then(res => {
                  if(res.status === 200){
                    this.profiledata = res.data;
                    console.log('profile is:', this.profiledata);
                    console.log(new Date().getMonth()+1+'-'+new Date().getFullYear());
                    this.requests = this.profiledata[0].rg[0].requests_count;
                    this.amountowing = this.profiledata[0].owing_amount.toFixed(2);
                    this.responsedata = true;
                    this.isloading = false;
                  }else{
                    window.clearTimeout(this.timeoutID);
                    this.$store.commit("clearUserData");
                    alert('Your Session has expired');
                    // this.$router.push("/login");
                    window.location.href = '/login';
                  }
                })
                .catch(error => console.log(error))
          }catch(e){
            console.error(e)
          }
        }else{
          try{
            let JWTToken = this.$store.getters.accessToken;
            axios.get('https://xarani.net/billing/transactions/?period='+month1+'-'+this.year, { headers: {"Authorization" : `Bearer ${JWTToken}`} })
                .then(res => {
                  if(res.status === 200){
                    this.profiledata = res.data;
                    console.log('profile is:', res.data);
                    console.log(new Date().getMonth()+1+'-'+new Date().getFullYear());
                    this.responsedata = true;
                    this.isloading = false;
                  }else{
                    window.clearTimeout(this.timeoutID);
                    this.$store.commit("clearUserData");
                    alert('Your Session has expired');
                    // this.$router.push("/login");
                    window.location.href = '/login';
                  }

                })
                .catch(error => console.log(error))
          }catch(e){
            console.error(e)
          }
        }

      },
      complete (index) {
        this.list[index] = !this.list[index]
      },
    },
  }
</script>
<style>
.application{
  display: none;
}
.v-dialog--custom {
  width: 100%;
}
</style>
